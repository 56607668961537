<template>
  <div class="vue-tempalte">
    <v-dialog name="dialog-reveal"/>
    <form>
      <h3>{{ $t("sidebar.signInClient") }}</h3>
      <br>

      <div class="form-group">
        <label>{{ $t("security.email") }}</label>
        <input type="email" v-model="email" class="form-control form-control-lg"/>
      </div>

      <div class="form-group">
        <label>{{ $t("security.password") }}</label>
        <input type="password" v-model="password" class="form-control form-control-lg"/>
      </div>


      <input type="button" @click='login();' :value="$t('security.client_login_button')" class="btn btn-primar">
<!--
      <p class="forgot-password text-right mt-2 mb-4">
        <router-link to="/forgot-password">{{ $t("security.forgotten_password") }}</router-link>
      </p>
-->      
    </form>
  </div>
</template>


<script>
import {socialvue} from '@/config/pluginInit'
import api from "@/api/api";

export default {
  name: 'LoginClientPage',
  mounted() {
    socialvue.index()
  },
  data() {
    return {
      email: '',
      password: '',
      submitted: false,

      badLoginDialog: {
        title: 'Přihlášení se nezdařilo',
        text: 'Ověřte si prosím zadané údaje.',
        buttons: [
          {
            title: 'Rozumím',
            handler: () => {
              this.$modal.hide('dialog')
            }
          }
        ]
      },
    }
  },
  created() {
    // reset login status
  },
  methods: {
    login() {
      api
          .post("client/login", {
            email: this.email,
            password: this.password
          })
          .then((response) => {
            if (response.data.status) {
              this.$store.dispatch('Setting/authUserAction', response.data)
              this.$store.dispatch('Setting/setClientData', response.data.client)
              this.$router.push({path: '/'})
            } else {
              this.$modal.show('dialog', this.badLoginDialog);
            }

          })
    }
  }
};
</script>

<style>
input[type="button"] {
  background-color: #ef476f;
  color: white;
  width: 100%;
  height: 50px;
  margin-top: 20px;
}

.vue-dialog {
  background-color: #182039;
  border-radius: 10px;
  border: 5px solid #ef476f;
}

.vue-dialog-content {
  padding: 30px;
}

.vue-dialog-buttons {
  border-top: 5px solid #ef476f;
}

.vue-dialog-button:not(:first-of-type) {
  border-left: 5px solid #ef476f;
}

.vue-dialog-button:hover {
  background: #ef476f;
}
</style>
